import React from 'react';
import Layout from '../../components/App/Layout';
import Navbar from '../../components/App/Navbar';
import ContentBanner from '../../components/Common/ContentBanner';
import Footer from '../../components/App/Footer';
import ProjectStartArea from '../../components/Common/StartProject';
import SEO from '../../components/App/SEO';
import exampleMetaImage from '../../assets/images/examples/mustBy/must-by.jpg';
import MustByCampaign from '../../components/Examples/MustByCampaign';

const Examples = () => {
  return (
	<Layout>
		<SEO
		  title="マストバイキャンペーンの事例・サンプル"
		  desc="リタッチではシリアルナンバー・レシートを使用したマストバイキャンペーンを開催することができます。応募から抽選まで一元管理できます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/must-by'}
		  type={'Article'}
		  shortName="マストバイキャンペーンの事例・サンプル"
		  createdAt="2021-03-02"
		/>
	  <Navbar />
	  <ContentBanner
		  pageTitle="マストバイキャンペーン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="マストバイキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />
	  <MustByCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples