import React, {useState} from 'react';
import {Link} from 'gatsby';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import OptimizeExample from './OptimizeExample';
import PopularExample from './PopularExample';
import WrapperStyle from './examples.style';
import Drawer from '../../components/UiComponent/drawer';
import ScriptTag from 'react-script-tag';
import {StaticImage} from 'gatsby-plugin-image';

const OurSolutions = () => {
  const [visible, setVisible] = useState(false);
  const [promotionId, setPromotionId] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState(null);
  const showDrawer = (id, title) => {
    setVisible(true);
    setPromotionId(id);
    setDrawerTitle(title)
  };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h1>マストバイキャンペーン
              </h1>
              <p>
                シリアルナンバーのマストバイキャンペーンを行うことができます。下記はシリアルナンバーのキャンペーンのサンプルです。
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <a data-retach-widget="embed" href="https://retach.biz/promotions/93f2a756fd98a25034" rel="nofollow">リタッチ</a>
                <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
              </div>
            </div>
          </div>
        </section>
        <section className="ptb-100 top-question" >
          <div className="container">
            <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
              <h2 id="question">よくあるご質問</h2>
            </div>
            <div className="faq-accordion">
              <Accordion allowMultipleExpanded={true}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      シリアルナンバーは自動発行できますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      いいえ。シリアルナンバーはお客様のご用意ください。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      シリアルナンバーの発行数に上限はありますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      はい。シリアルナンバーは１つから複数まで設定できます。<br/>
                      シリアルナンバーの上限数は利用金プランによって異なりますので、<Link href="/pricing" >こちら</Link>からご確認ください。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      インスタントウィンとしてその場で賞品を提供することは可能ですか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      はい。シークレットナンバーの応募方法は、キャンペーンとインスタントウィン両方でご使用いただけます。
                      TwitterやLINの応募方法を組み合わせて、従来より多機能な応募方法を作成することができます。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      写真を公開しないクローズドなフォトコンテストを開催できますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      はい。写真を公開しない場合は、キャンペーン機能を使ってフォトコンテストを開催してください。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      シリアルナンバーに日本語は設定できますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      はい。日本語に対応することも可能です。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      応募者の住所や電話番号を取得できますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      はい。応募者に入力して頂く項目は自由に変更できます。応募者情報の設定についてはこちらをご覧ください。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>
        <OptimizeExample  types={['password','keepWatching','share']} showDrawer={showDrawer}/>
        <PopularExample exclude={['mustBy','password']} showDrawer={showDrawer}/>
        <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};

export default OurSolutions